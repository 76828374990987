import React, { useState, useRef, useCallback, useEffect } from 'react';
import './CreditCardOptimizer.css';

const CreditCardOptimizer = () => {
  const [cards, setCards] = useState([]);
  const [purchase, setPurchase] = useState([]);
  const [availableCategories, setAvailableCategories] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const fileInputRef = useRef(null);
  const [activeTab, setActiveTab] = useState('purchase');

  // Load cards from localStorage when the component mounts
  useEffect(() => {
    const savedCards = localStorage.getItem('creditCards');
    if (savedCards) {
      setCards(JSON.parse(savedCards));
      // Update available categories based on loaded cards
      const loadedCategories = JSON.parse(savedCards).flatMap(card => 
        card.rewards.map(r => r.category)
      );
      setAvailableCategories([...new Set(loadedCategories)]);
    }
  }, []);

  // Save cards to localStorage whenever they change
  useEffect(() => {
    localStorage.setItem('creditCards', JSON.stringify(cards));
  }, [cards]);

  const addCard = (card) => {
    const updatedCards = [...cards, card];
    setCards(updatedCards);
    const newCategories = card.rewards.map(r => r.category);
    setAvailableCategories([...new Set([...availableCategories, ...newCategories])]);
  };

  const toggleCategory = useCallback((category) => {
    setPurchase(prev => 
      prev.includes(category) 
        ? prev.filter(c => c !== category)
        : [...prev, category]
    );
  }, []);

  const isRewardApplicable = (reward, date) => {
    if (!reward.applicableDays && !reward.applicableMonths) return true;
    
    const day = date.getDate();
    const month = date.getMonth() + 1;

    console.log(`Checking applicability for reward: ${reward.category}, ${reward.percentage}%`);
    console.log(`Date: ${date.toISOString()}, Day: ${day}, Month: ${month}`);
    console.log(`Applicable Days: ${reward.applicableDays}, Applicable Months: ${reward.applicableMonths}`);

    if (reward.applicableDays && !reward.applicableDays.includes(day)) {
      console.log('Not applicable due to day');
      return false;
    }
    if (reward.applicableMonths && !reward.applicableMonths.includes(month)) {
      console.log('Not applicable due to month');
      return false;
    }

    console.log('Reward is applicable');
    return true;
  };

  const findBestCards = () => {
    if (cards.length === 0 || purchase.length === 0) return [];

    const cardRewards = cards.map(card => {
      const reward = Math.max(...purchase.map(category => {
        const categoryRewards = card.rewards.filter(r => r.category === category);
        return Math.max(...categoryRewards.map(r => 
          isRewardApplicable(r, selectedDate) ? r.percentage : 0
        ), 0);
      }));
      return { card: card.name, reward };
    });

    const maxReward = Math.max(...cardRewards.map(cr => cr.reward));
    return cardRewards.filter(cr => cr.reward === maxReward);
  };

  const saveCardsToFile = () => {
    const data = JSON.stringify(cards, null, 2);
    const blob = new Blob([data], { type: 'application/json' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'credit_cards.json';
    link.click();
    URL.revokeObjectURL(url);
  };

  const loadCardsFromFile = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        try {
          const loadedCards = JSON.parse(e.target.result);
          setCards(loadedCards);
          const newCategories = loadedCards.flatMap(card => card.rewards.map(r => r.category));
          setAvailableCategories([...new Set(newCategories)]);
          
          // Save loaded cards to localStorage
          localStorage.setItem('creditCards', JSON.stringify(loadedCards));
          
          alert('Cards loaded successfully and saved to browser storage.');
        } catch (error) {
          console.error('Error parsing JSON file:', error);
          alert('Error loading file. Please make sure it\'s a valid JSON file.');
        }
      };
      reader.readAsText(file);
    }
  };

  return (
    <div className="credit-card-optimizer">
      <h1>Credit Card Optimizer</h1>
      
      <div className="tabs">
        <button 
          className={activeTab === 'purchase' ? 'active' : ''} 
          onClick={() => setActiveTab('purchase')}
        >
          Purchase Info
        </button>
        <button 
          className={activeTab === 'cards' ? 'active' : ''} 
          onClick={() => setActiveTab('cards')}
        >
          Manage Cards
        </button>
      </div>

      {activeTab === 'purchase' && (
        <div className="tab-content">
          <h2>Select Purchase Categories</h2>
          <CategorySelector 
            categories={availableCategories}
            selectedCategories={purchase}
            onToggleCategory={toggleCategory}
          />
          
          <h2>Set Purchase Date</h2>
          <input
            type="date"
            value={selectedDate.toISOString().split('T')[0]}
            onChange={(e) => {
              const [year, month, day] = e.target.value.split('-');
              const newDate = new Date(year, month - 1, day, 12, 0, 0);
              setSelectedDate(newDate);
            }}
          />
          
          <BestCardDisplay bestCards={findBestCards()} selectedDate={selectedDate} />
        </div>
      )}

      {activeTab === 'cards' && (
        <div className="tab-content">
          <h2>Add Credit Card</h2>
          <CardForm onAddCard={addCard} />
          
          <h2>Your Credit Cards</h2>
          <CardList cards={cards} />
          
          <h2>Card Management</h2>
          <div className="card-management">
            <button onClick={saveCardsToFile}>Save Cards to File</button>
            <input
              type="file"
              ref={fileInputRef}
              style={{ display: 'none' }}
              onChange={loadCardsFromFile}
              accept=".json"
            />
            <button onClick={() => fileInputRef.current.click()}>Load Cards from File</button>
          </div>
        </div>
      )}
    </div>
  );
};

const CardForm = ({ onAddCard }) => {
  const [name, setName] = useState('');
  const [rewards, setRewards] = useState([]);
  const [category, setCategory] = useState('');
  const [percentage, setPercentage] = useState('');
  const [applicableDays, setApplicableDays] = useState('');
  const [applicableMonths, setApplicableMonths] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    onAddCard({ name, rewards });
    setName('');
    setRewards([]);
  };

  const addReward = () => {
    if (category && percentage) {
      const newReward = { 
        category, 
        percentage: parseFloat(percentage),
        applicableDays: applicableDays ? applicableDays.split(',').map(Number) : undefined,
        applicableMonths: applicableMonths ? applicableMonths.split(',').map(Number) : undefined,
      };
      setRewards([...rewards, newReward]);
      setCategory('');
      setPercentage('');
      setApplicableDays('');
      setApplicableMonths('');
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <input
        type="text"
        value={name}
        onChange={(e) => setName(e.target.value)}
        placeholder="Card Name"
        required
      />
      <input
        type="text"
        value={category}
        onChange={(e) => setCategory(e.target.value)}
        placeholder="Reward Category"
      />
      <input
        type="number"
        value={percentage}
        onChange={(e) => setPercentage(e.target.value)}
        placeholder="Reward Percentage"
      />
      <input
        type="text"
        value={applicableDays}
        onChange={(e) => setApplicableDays(e.target.value)}
        placeholder="Applicable Days (comma-separated)"
      />
      <input
        type="text"
        value={applicableMonths}
        onChange={(e) => setApplicableMonths(e.target.value)}
        placeholder="Applicable Months (comma-separated)"
      />
      <button type="button" onClick={addReward}>Add Reward</button>
      <ul>
        {rewards.map((reward, index) => (
          <li key={index}>
            {reward.category}: {reward.percentage}%
            {reward.applicableDays && ` (Days: ${reward.applicableDays.join(', ')})`}
            {reward.applicableMonths && ` (Months: ${reward.applicableMonths.join(', ')})`}
          </li>
        ))}
      </ul>
      <button type="submit">Add Card</button>
    </form>
  );
};

const CardList = ({ cards }) => (
  <ul>
    {cards.map((card, index) => (
      <li key={index}>
        <h3>{card.name}</h3>
        {Object.entries(groupRewardsByCategory(card.rewards)).map(([category, categoryRewards]) => (
          <div key={category}>
            <strong>{category}:</strong>
            {categoryRewards.map((reward, rewardIndex) => (
              <div key={rewardIndex}>
                {reward.percentage}%
                {reward.applicableDays && ` (Days: ${reward.applicableDays.join(', ')})`}
                {reward.applicableMonths && ` (Months: ${reward.applicableMonths.join(', ')})`}
              </div>
            ))}
          </div>
        ))}
      </li>
    ))}
  </ul>
);

const groupRewardsByCategory = (rewards) => {
  return rewards.reduce((acc, reward) => {
    if (!acc[reward.category]) {
      acc[reward.category] = [];
    }
    acc[reward.category].push(reward);
    return acc;
  }, {});
};

const CategorySelector = ({ categories, selectedCategories, onToggleCategory }) => (
  <div className="category-selector">
    {categories.map((category) => (
      <button
        key={category}
        className={`category-button ${selectedCategories.includes(category) ? 'selected' : ''}`}
        onClick={() => onToggleCategory(category)}
      >
        {category}
      </button>
    ))}
  </div>
);

const BestCardDisplay = ({ bestCards, selectedDate }) => (
  <div className="best-card">
    <h3>Best Card(s) for Purchase on {selectedDate.toLocaleDateString()}:</h3>
    {bestCards.map((card, index) => (
      <div key={index}>
        <p>{card.card}: {card.reward}% cashback</p>
      </div>
    ))}
  </div>
);

export default CreditCardOptimizer;